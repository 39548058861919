import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConstantsService } from '../constants.service';
import { AppservicesService } from '../appservices.service';

declare var $:any; 

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  baseUrlApp: string;
  urlPath: string;

  constructor(
    private router: Router,
    private _constant: ConstantsService,
    private appServices: AppservicesService,
    private activatedRoute: ActivatedRoute
  ) { 
      this.baseUrlApp = _constant.baseAppUrl;
      this.urlPath = _constant.urlPath;
   }

  ngOnInit() {
  }

}
