import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {KnightsbridgeComponent} from './knightsbridge/knightsbridge.component';
import {HomeComponent} from './home/home.component';
import {MayfairComponent} from './mayfair/mayfair.component';
import {CareersComponent} from './careers/careers.component';
import {MayfairFrontHousePositionComponent} from './mayfair-front-house-position/mayfair-front-house-position.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {VoucherPageComponent} from './voucher-page/voucher-page.component';

const routes: Routes = [
{ path: '', component: HomeComponent, data: {animation: 'Home'} },
{ path: 'knightsbridge', component: KnightsbridgeComponent, data: {animation: 'knightbridge'} },
{ path: 'mayfair', component: MayfairComponent,  data: {animation: 'mayfair'}},
{ path: 'careers', component: CareersComponent},
{ path: 'mayfair-front-house-position', component: MayfairFrontHousePositionComponent},
{ path: 'privacy-policy', component: PrivacyPolicyComponent},
{ path: 'voucher', component: VoucherPageComponent},
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
