import { Injectable } from '@angular/core';
declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class AppservicesService {

   constructor() { }

   executeJQueryFunctions() {
  	 $(document).ready(function(){
  	   //$('#voucher_with_terms').modal('show');

  	   $('.view-terms-and-conditions').click(function(e){
  	     e.preventDefault();
  	     $('#voucher_with_terms .voucher-lower-text').show();
  	   });

  	   $('.press-clippings-container').not('.slick-initialized').slick({
  	     dots: true,
  	     infinite: true,
  	     speed: 500,
  	     autoplay: true,
  	     autoplaySpeed: 5000,
  	     slidesToShow: 4,
  	     slidesToScroll: 4,
  	     arrows: false,
  	     responsive: [
  	       {
  	         breakpoint: 1024,
  	         settings: {
  	           slidesToShow: 3,
  	           slidesToScroll: 3,
  	           infinite: true,
  	           dots: false
  	         }
  	       },
  	       {
  	         breakpoint: 768,
  	         settings: {
  	           slidesToShow: 1,
  	           slidesToScroll: 1
  	         }
  	       },
  	       {
  	         breakpoint: 480,
  	         settings: {
  	           slidesToShow: 1,
  	           slidesToScroll: 1
  	         }
  	       }
  	       // You can unslick at a given breakpoint now by adding:
  	       // settings: "unslick"
  	       // instead of a settings object
  	     ]
  	   });

  	   /*$('.select-location-close a').click(function(e){
  	     e.preventDefault();

  	     $('.select-location--container').animate({
  	       opacity: 0,
  	     }, 1000, function() {
  	       //completion code??
  	     });
  	   });*/

  	   /*$('.header--book-table-close-button-container a').click(function(e){
  	     e.preventDefault();

  	     $('.header--book-table-inner-page').animate({
  	       opacity: 0,
  	     }, 1000, function() {
  	       //completion code??
  	     });
  	   });*/
      /*$(document).click(function(event) { 
           if (!$(event.target).closest("#slide-out-panel-menu, .header--anchor-bars").length) {
               $(".slide-out-menu-panel").animate({left: '-465px'}, 250, function(){
                 $('.slide-out-menu-container').fadeOut(200);
               });
           }
       });*/
      // accordion
      var allPanels = $('.accordion .accordion-panel').hide();

      $('.accordion a.accordion-switch').click(function() {
         $(this).parent().next().slideToggle();

         $(this).find('i').toggleClass('fa-plus-square-o fa-minus-square-o');
         $('.accordion-switch').not($(this)).find('i').removeClass('fa-minus-square-o').addClass('fa-plus-square-o');

         $('.accordion .accordion-panel').not($(this).parent().next()).slideUp('fast');  

          return false;
        });
      // end accordion

       $('a.anchor-bars-toggle').click(function(e){ 
         e.preventDefault();
         $('body').addClass('slide-out-active');
         $(".slide-out-menu-panel").animate({left: '0'}, 250, function(){});
         $('.slide-out-menu-container').fadeIn(200);
       });

       $('a.slide-out-panel-close-button').click(function(e){
         e.preventDefault();
         $('body').removeClass('slide-out-active');
          $(".slide-out-menu-panel").animate({left: '-465px'}, 250, function(){
            $('.slide-out-menu-container').fadeOut(200);
          });
       });

       $('.slide-out-menu-links.jquery ul li a').click(function(e){
         e.preventDefault();
         var anchor = $(this).attr('data-attr-scroll-div');

         $(".slide-out-menu-panel").animate({left: '-465px'}, 250, function(){
           $('.slide-out-menu-container').fadeOut(200);
         });

         if($(this).attr('data-attr-scroll-div') != '' && $(this).attr('data-attr-scroll-div') != undefined){
             $('html, body').animate({
                   scrollTop: $("#" + anchor).offset().top
               }, 400);
             }
         
       });
  	 }); 		
   }  
}
