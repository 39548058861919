import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../constants.service';
import { AppservicesService } from '../appservices.service';
import { RoutingPaths } from '../irouting.service';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {
   baseUrlApp: string;

  constructor(private _constant: ConstantsService) { 
  	this.baseUrlApp = _constant.baseAppUrl;
  }

  ngOnInit() {
  }

}
