import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
  animateChild
} from '@angular/animations';


export const slideInAnimation =
  trigger('routeAnimations', [
      transition('knightbridge => *', [
          query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),
          group([
            query(':enter', [
                style({ opacity: 1 }),
                animate('0.5s', style({ opacity: 1 }))
              ], { optional: true }),
              query(':leave', [
                style({ opacity: 1 }),
                animate('0.5s', style({ opacity: 0 }))
              ], { optional: true }),
          ])
      ]),
      transition('Home => *', [
          query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),
          group([
            query(':enter', [
                style({ opacity: 1 }),
                animate('0.5s', style({ opacity: 1 }))
              ], { optional: true }),
              query(':leave', [
                style({ opacity: 1 }),
                animate('0.5s', style({ opacity: 0 }))
              ], { optional: true }),
          ])
      ]),
      transition('mayfair => *', [
        query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),
        group([
            query(':enter', [
                style({ opacity: 1 }),
                animate('0.5s', style({ opacity: 1 }))
              ], { optional: true }),
              query(':leave', [
                style({ opacity: 1 }),
                animate('0.5s', style({ opacity: 0 }))
              ], { optional: true }),
        ])
    ]),
        transition('careers => *', [
          query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),
          group([
              query(':enter', [
                  style({ opacity: 1 }),
                  animate('0.5s', style({ opacity: 1 }))
                ], { optional: true }),
                query(':leave', [
                  style({ opacity: 1 }),
                  animate('0.5s', style({ opacity: 0 }))
                ], { optional: true }),
          ])
      ])      
      // transition('About => Home', [
      //     query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
      //     group([
      //         query(':enter', [
      //             style({ transform: 'translateX(-100%)' }),
      //             animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
      //         ], { optional: true }),
      //         query(':leave', [
      //             style({ transform: 'translateX(0%)' }),
      //             animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' }))
      //         ], { optional: true }),
      //     ])
      // ]),
  ]);