import { Injectable } from '@angular/core';
import { RoutingPaths } from './irouting.service';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
	readonly baseAppUrl: string = ''; // file path
	readonly urlPath: string = '/'; // url routing path
	readonly routingPaths: RoutingPaths[] = [
		{id: 'careers', path: this.urlPath+'careers'},
		{id: 'mayfair_job1', path: this.urlPath+'mayfair-front-house-position'},
		{id: 'privacy_policy', path: this.urlPath+'privacy-policy'},
		{id: 'home', path: this.urlPath+''}
	];

  	constructor() { }
}
