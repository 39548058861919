import { Component, OnInit, AfterViewInit, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from '../constants.service';
import { AppservicesService } from '../appservices.service';

declare var $:any;

@Component({
  selector: 'app-mayfair-front-house-position',
  templateUrl: './mayfair-front-house-position.component.html',
  styleUrls: ['./mayfair-front-house-position.component.css']
})
export class MayfairFrontHousePositionComponent implements OnInit, AfterViewInit {
  display_none:any;
  transition:any;
  baseUrlApp: string;
  urlPath: string;
  routingPaths: Array<Object>;

@HostListener('document:click', ['$event'])
   clickout(event) {
   if (!$(event.target).closest("#slide-out-panel-menu, .header--anchor-bars").length) {
      $(".slide-out-menu-panel").animate({left: '-465px'}, 250, function(){
        $('.slide-out-menu-container').fadeOut(200);
      });
   }
}

  constructor(
    private router: Router,
    private _constant: ConstantsService,
    private appServices: AppservicesService
  ) { 
      this.baseUrlApp = _constant.baseAppUrl;
      this.urlPath = _constant.urlPath;
      this.routingPaths = _constant.routingPaths;
   }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.appServices.executeJQueryFunctions();
  }

  directknight() {
    this.router.navigate([this.urlPath+'knightsbridge']);
  }
  direct() {
    this.router.navigate([this.urlPath+'mayfair']);
  }
  home() {
    this.router.navigate([this.urlPath]);
  }
  side_menu_block() {
    this.display_none = "block";

  }
  display_none_menu() {
    this.display_none = "none";
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    this.display_none = "none";
}
}
