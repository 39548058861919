import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConstantsService } from '../constants.service';
import { AppservicesService } from '../appservices.service';

@Component({
  selector: 'app-philosophy',
  templateUrl: './philosophy.component.html',
  styleUrls: ['./philosophy.component.css']
})
export class PhilosophyComponent implements OnInit {
  baseUrlApp: string;
  urlPath: string;

  constructor(
    private router: Router,
    private _constant: ConstantsService,
    private appServices: AppservicesService,
    private activatedRoute: ActivatedRoute
  ) { 
      this.baseUrlApp = _constant.baseAppUrl;
      this.urlPath = _constant.urlPath;
   }

  ngOnInit() {
  }

}
