import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { KnightsbridgeComponent } from './knightsbridge/knightsbridge.component';
import { MayfairComponent } from './mayfair/mayfair.component';
import { GlobalsComponent } from './globals/globals.component';
import { ConstantsService } from './constants.service';
import { CareersComponent } from './careers/careers.component';
import { MayfairFrontHousePositionComponent } from './mayfair-front-house-position/mayfair-front-house-position.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SafePipe } from './safe_pipe.component';
import { FooterComponent } from './footer/footer.component';
import { VoucherComponent } from './voucher/voucher.component';
import { PressClippingsComponent } from './press-clippings/press-clippings.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { Sidebar1Component } from './sidebar1/sidebar1.component';
import { PhilosophyComponent } from './philosophy/philosophy.component';
import { Sidebar2Component } from './sidebar2/sidebar2.component';
import { Sidebar3Component } from './sidebar3/sidebar3.component';
import { Sidebar4Component } from './sidebar4/sidebar4.component';
import { VoucherPageComponent } from './voucher-page/voucher-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    KnightsbridgeComponent,
    MayfairComponent,
    GlobalsComponent,
    CareersComponent,
    MayfairFrontHousePositionComponent,
    PrivacyPolicyComponent,
    SafePipe,
    FooterComponent,
    VoucherComponent,
    PressClippingsComponent,
    AboutUsComponent,
    Sidebar1Component,
    PhilosophyComponent,
    Sidebar2Component,
    Sidebar3Component,
    Sidebar4Component,
    VoucherPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule
  ],
  providers: [ConstantsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
