import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConstantsService } from '../constants.service';
import { AppservicesService } from '../appservices.service';

@Component({
  selector: 'app-press-clippings',
  templateUrl: './press-clippings.component.html',
  styleUrls: ['./press-clippings.component.css']
})
export class PressClippingsComponent implements OnInit {
  baseUrlApp: string;
  urlPath: string;

  constructor(
    private router: Router,
    private _constant: ConstantsService,
    private appServices: AppservicesService,
    private activatedRoute: ActivatedRoute
  ) { 
      this.baseUrlApp = _constant.baseAppUrl;
      this.urlPath = _constant.urlPath;
   }

  ngOnInit() {
  }

}
