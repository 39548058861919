import { Component, OnInit, AfterViewInit } from '@angular/core';
import { slideInAnimation } from './animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit, AfterViewInit {
	ngOnInit() {

	}
	
	ngAfterViewInit(){

	}
}
