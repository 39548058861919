import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from '../constants.service';
import { AppservicesService } from '../appservices.service';
import { RoutingPaths } from '../irouting.service';

@Component({
  selector: 'app-sidebar1',
  templateUrl: './sidebar1.component.html',
  styleUrls: ['./sidebar1.component.css']
})
export class Sidebar1Component implements OnInit, AfterViewInit {
  display_none:any;
  transition:any;
  baseUrlApp: string;
  urlPath: string;
  routingPaths: RoutingPaths[] = [];
  iframeSource: Array<Object> = [
    {id: 1, source: 'https://snazzymaps.com/embed/179770'},
    {id: 2, source: 'https://snazzymaps.com/embed/179774'}
  ];

  constructor(
    private router: Router,
    private _constant: ConstantsService,
    private appServices: AppservicesService
  ) { 
      this.baseUrlApp = _constant.baseAppUrl;
      this.urlPath = _constant.urlPath;
      this.routingPaths = _constant.routingPaths;
   }

  ngOnInit() {
  }

  ngAfterViewInit() {
    //this.appServices.executeJQueryFunctions();
  }

  directknight() {
    this.router.navigate([this.urlPath+'knightsbridge']);
  }
  direct() {
    this.router.navigate([this.urlPath+'mayfair']);
  }
  home() {
    this.router.navigate([this.urlPath]);
  }
  side_menu_block() {
    this.display_none = "block";

  }
  display_none_menu() {
    /*this.display_none = "none";*/
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    this.display_none = "none";
  }
}
